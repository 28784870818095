import * as React from "react";
import { GearType } from "../../../../models/InstructorModel/interfaces";
import { IReadonlyPendingStatus } from "../../../../models/PendingStatus";
import { IDatePickerOption } from "../../../shared/DatePicker";
import { IEventsListScreenModel } from "../../EventsListScreen/interfaces";

export interface ICalendarModel {
  readonly legendVisible: boolean;
  readonly date: Date;
  readonly selectedDay: Date | undefined;
  readonly selectedDayOption:
    | IDatePickerOption<ICalendarEvent<any>>
    | undefined;
  readonly selectedDayEvents: ReadonlyArray<ICalendarEvent<any>>;
  readonly selectableDays: ReadonlyArray<
    IDatePickerOption<ICalendarEvent<any>>
  >;
  readonly events: ReadonlyArray<ICalendarEvent<any>>;
  readonly eventsReloading: IReadonlyPendingStatus;
  readonly lessonsLeft: number;
  setDay(date: Date): void;
  selectDay(date: Date | undefined): void;
  showLegend(): void;
  hideLegend(): void;
  mount(): Promise<void>;
  unmount(): void;
}

export interface IEventDetailViewBaseProps<T extends ICalendarEvent<any>> {
  event: T;
  model: IEventsListScreenModel;
}

export type EventDetailViewType<T extends ICalendarEvent<any>> =
  React.ComponentType<IEventDetailViewBaseProps<T>>;

export interface ICalendarEvent<E extends ICalendarEvent<any>> {
  readonly id: number;
  readonly name: string;
  readonly from: Date;
  readonly to: Date;
  readonly typeKey: CalendarEventType;
  readonly externalComment?: string;
  isPaid?: boolean;
  // TODO: calendar ceil view
  getEventDetailView(): Promise<EventDetailViewType<E>>;
  isEditable(): boolean;
  isEditableViaSupport(): boolean;
  setTypeKey(typeKey: CalendarEventType): void;
  setFrom(from: Date): void;
  setTo(to: Date): void;
  setId(id: number): void;
  setName(name: string): void;
}

export enum CalendarEventType {
  Test = "testLesson",
  Lesson = "lesson",
  TheoryLesson = "theoryLesson",
  EducationControl = "educationControl",
  Intro = "intro",
  Risk1 = "risk1",
  Risk2 = "risk2",
  Moped = "moped",
  MopedDay = "mopedday",
  Taxi = "taxiLesson",
  TheoryGroup = "theoryGroup",
  Simulator = "simulator",
  BELesson = "BELesson",
  TestDriving = "testDriving",
  DrivingTest = "drivingTest",
  Intensive = "Intensive",
}
